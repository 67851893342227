<template>
    <section id="development_banner">
        <div class="development_banner_bg"></div>
        <div class="container development_container">
            <div>
                <h1>Рекламное агентство<br><span>Окей Город</span></h1>
                <div class="development_container_description_box">
                    <span>• Больше 8 лет в сфере интернет рекламы</span>
                    <span>• Создатели городского приложения "Окей Город"</span>
                    <span>• Своя команда разработчиков, дизайнеров и smm специалистов</span>
                </div>
            </div>
                <div class="development_container_button_box">
                    <a href="#development_contacts"><button type="button">Заказать увелечение продаж</button></a>
                    <a href="#development_contacts"><button type="button">Заказать экспертный аудит</button></a>
                    
                    
                </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "development_header",
    components: {},
    data() {
        return {

        }
    }

}
</script>
<style scoped>
#development_banner {
    background: #eee;
    min-height: 100vh;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.development_banner_bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('./images/8fbQhcveIKg.jpg');
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.development_banner_bg:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,0.8), transparent);
    z-index: -1;
}

.development_banner_bg:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    /*background: rgba(255, 255, 255, 0.8);*/
    filter: blur(5px);
    z-index: -1;
}

#development_banner .container {
    z-index: 10;
}

.development_banner {
    display: block;
    height: 300px;
    border-radius: 12px;
    background: #fff;
}

.development_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.development_container h1 {
    color: #fff;
    font-size: 34px;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.development_container h1 span {
    font-size: 78px;
    text-transform: none;
    /*color: rgba(14, 198, 69, 1.0);*/
    /*color: rgba(14, 198, 69, 1.0);*/
}

.development_container_description_box {
}

.development_container_description_box span {
    display: block;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
}

.development_container_button_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.development_container_button_box a {
    width: 100%;
}

.development_container_button_box button {
    padding: 25px;
    width: 100%;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 22px;
    outline: none;
    border: none;
    background: rgba(var(--main-color), 1.0);
    color: #fff;
}

@media (max-width: 1200px) {
    .development_container {
        gap: 15px;
    }

    .development_container h1 {
        font-size: 2vw;
        margin-bottom: 50px;
        text-align: left;
    }

    .development_container h1 span {
        font-size: 5vw;
    }

    .development_container_description_box {
        margin-bottom: 50px;
    }

    .development_container_description_box span {
        font-size: 1.3vw;
    }

    .development_container_button_box button {
        padding: 15px 25px;
        width: 100%;
        font-size: 1.5vw;
    }
}

@media (max-width: 991.9px) {

    .development_container h1 {
        font-size: 5vw;
        margin-bottom: 50px;
        text-align: center;
    }

    .development_container h1 span {
        font-size: 10vw;
    }

    .development_container_description_box {
        margin-bottom: 50px;
    }

    .development_container_description_box span {
        font-size: 1.9vw;
    }

    .development_container_button_box button {
        padding: 15px 25px;
        width: 100%;
        font-size: 3vw;
    }

    .development_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }
}

@media (max-width: 576px) {
    .development_banner_bg {
        background-position: center;
        background-attachment: scroll;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .development_container h1 {
        font-size: 5vw;
        margin-bottom: 50px;
        text-align: center;
    }

    .development_container h1 span {
        font-size: 10vw;
    }

    .development_container_description_box {
        margin-bottom: 50px;
    }
    .development_container_description_box span {
        font-size: 3.5vw;
    }

    .development_container_button_box button {
        padding: 10px 15px;
        width: 100%;
        font-size: 4vw;
    }

    .development_banner_bg:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: -1;
    }
}
</style>