<template>
    <div>
        <div v-if="preloader">
            <form v-if="!form_success" class="development_form" @submit.prevent="send">
                <span class="development_form_info">Оставьте свои контакты и мы Вам перезвоним</span>
                <div class="development_form_item">
                    <input type="text" placeholder="Ваше имя" minlength="3" v-model="form.name" required>
                </div>
                <div class="development_form_item">
                    <input type="text" placeholder="Телефон" v-model="form.telephone" required>
                </div>
                <div class="development_form_item">
                    <input type="email" placeholder="E-mail" v-model="form.email" required>
                </div>
                <div class="development_form_item">
                    <textarea v-$autogrow type="text" maxlength="2000" placeholder="Текст обращения" v-model="form.text"></textarea>
                </div>
                <button class="development_form_button" type="submit">Отправить</button>
            </form>
            <div v-else class="form_success">
                <legend>Спасибо за обращение!</legend>
                <span>В ближайшее время мы свяжемся с Вами.</span>
            </div>
        </div>
        <developmentPreloader v-else />
    </div>
</template>
<script>
    import developmentPreloader from './preloader';
export default {
    name: "development_form",
    components: {
        developmentPreloader
    },
    data() {
        return {
            preloader: true,
            form_success: false,
            form: {
                name: null,
                telephone: null,
                email: null,
                text: null,
                service: {}
            }
        }
    },
    methods: {
        send() {
            this.preloader = false;
            let url = this.$config.api_url + 'ramenskoye/Advertising.form';
            this.$http.post(url, this.form, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(() => {
                this.form_success = true;
            }).finally(() => {
                this.preloader = true;
            });
        }
    }

}
</script>
<style scoped>
.development_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgb(117 115 111 / 10%);
}

.development_form_item {
    width: 100%;
}

.development_form_item input,
.development_form_item textarea {
    border: none;
    outline: none;
    padding: 10px 15px;
    border-radius: 4px;
    background: #f9f9f9;
    width: 100%;
    transition: .2s;
    position: relative;
    z-index: 1;
}

.development_form_item input:not(:valid) {
    border-right: 4px solid red;
    transition: .2s;
}

.development_form_item input:valid {
    border-right: 4px solid green;
    transition: .2s;
}


.development_form_item textarea::-moz-scrollbar {
    width: 4px;
    height: 4px;
}

.development_form_item textarea::-moz-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.development_form_item textarea::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}


.development_form_item textarea::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.development_form_button {
    background: rgba(var(--main-color), 1.0);
    width: 100%;
    margin-top: 15px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 15px;
}

.development_form_info {
    color: #919191;
    text-align: center;
    width: 100%;
}

.development_preloader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgb(117 115 111 / 10%);
    height: 100%;
}

.development_preloader span {
    width: 100%;
    background: #eee;
    border-radius: 4px;
    height: 50px;
    position: relative;
}

.development_preloader span .line {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: shine 1.5s infinite linear;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.form_success {
    gap: 15px;
    background: #fff;
    padding: 15px;
    box-shadow: 0 4px 20px rgb(117 115 111 / 10%);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.form_success legend {
    text-align: center;
    font-weight: bold;
}
</style>