<template>
    <div class="development_preloader">
        <span>
            <div class="line"></div>
        </span>
        <span>
            <div class="line"></div>
        </span>
        <span>
            <div class="line"></div>
        </span>
        <span>
            <div class="line"></div>
        </span>
        <span>
            <div class="line"></div>
        </span>
        <span>
            <div class="line"></div>
        </span>
    </div>
</template>
<script>
export default {
    name: "development_preloader",
    components: {},
    data() {
        return {}
    },
    methods: {

    }

}
</script>
<style scoped>
.development_preloader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgb(117 115 111 / 10%);
    height: 100%;
}

.development_preloader span {
    width: 100%;
    background: #eee;
    border-radius: 4px;
    height: 50px;
    position: relative;
}

.development_preloader span .line {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: shine 1.5s infinite linear;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}


@media (max-width: 1200px) {

}

@media (max-width: 991.9px) {

}

@media (max-width: 576px) {

}
</style>