<template>
    <div id="development_page">
        <b-navbar toggleable="sm" type="light" class="development_navbar">
            <div class="container development_navbar_container">
                <router-link :to="{ name: 'DeveloperLanding' }">
                    <div class="development_navbar_logo_box">
                        <img src="/images/logoHD.svg" alt="logo">
                        <span>Окей Город</span>
                    </div>
                </router-link>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav class="development_navbar_link_box">
                    <b-navbar-nav>
                        <b-nav-item href="#development_services">Услуги</b-nav-item>
                        <b-nav-item href="#development_contacts">Контакты</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-navbar>
        <!-- <nav class="development_navbar">
            <div class="container development_navbar_container">
                <router-link :to="{ name: 'DeveloperLanding' }">
                <div class="development_navbar_logo_box">
                    <img src="/images/logoHD.svg" alt="logo">
                    <span>Окей Город</span>
                </div>
                </router-link>
                <div class="development_navbar_link_box">
                    <a href="#development_services">Услуги</a>
                    <a href="#development_contacts">Контакты</a>
                </div>
            </div>
        </nav> -->
        <developmentHeader />
        <services />
        <developmentContacts />
        <footer>
            <span>© Все права защищены / All rights reserved</span>
            <span>Разработано компанией <strong itemprop="copyrightHolder" v-html="`Окей Город`"></strong></span>
            <span itemprop="copyrightYear" v-html="`2017 - ${$moment().format('YYYY г.')}`" class="leftMenu_footer_date"></span>
        </footer>
    </div>
</template>
<script>
import developmentHeader from './components/header';
import services from './components/services';
import developmentContacts from './components/contacts';
export default {
    name: "DeveloperLanding",
    components: {
        developmentHeader,
        services,
        developmentContacts
    },
    metaInfo() {
        return {
            title: `Рекламное агентство "Окей Город"`,
            meta: [
                { name: 'description', itemprop: 'description', content: `Редакция информационного портала Окей Город ${this.$myCity.name}` },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: `Реклама  - ${this.$myCity.name}` },
                { property: 'og:description', content: `Редакция информационного портала Окей Город ${this.$myCity.name}` },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },

}
</script>
<style scoped>
section {
    padding: 50px 0;
}

#development_page {
    background: #f9f9f9;
    position: relative;
    font-family: 'Roboto';
}

.development_navbar_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.development_navbar {
    background: #fff;
    padding: 10px 15px;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 20px rgb(117 115 111 / 20%);
    z-index: 100;
}

.development_navbar_logo_box {
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: bold;
    color: #919191;
}

.development_navbar_logo_box img {
    max-height: 40px;
    border-radius: 4px;
}

.development_navbar_link_box .nav-link {
    color: #919191;
    font-weight: bold;
    transition: .2s;
}

.development_navbar_link_box a:hover {
    color: rgba(var(--main-color), 1.0);
    transition: .2s;
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #919191;
    font-size: 12px;
    padding: 120px 0;
}

@media screen and (max-width: 540px) {
    .development_navbar_link_box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 20px;
        width: 100%;
    }

    .navbar-nav {
        width: 100%;
    }
}

@media (min-width: 540.1px) {

    .development_navbar_link_box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
    }
}
</style>