<template>
    <section id="development_services">
        <h2>Наши услуги и цены</h2>
        <div class="container">
            <div class="development_services_item" v-for="(item,index) in items" :key="index">
                <!-- <img :src="require(`./images/${item.image}`)" alt="service_image" class="img-fluid"> -->
                <div class="development_services_item_image_box">
                    <div class="development_services_item_icon" :class="item.class"></div>
                </div>
                <h3>{{ item.name }}</h3>
                <p v-html="item.description"></p>
                <div class="development_services_item_footer">
                <span class="development_services_item_price">от {{ item.price.toLocaleString() }} ₽</span>
                <a href="#development_contacts">
                    <button type="button" class="development_services_item_button">Получить консультацию</button>
                </a>
                    
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "development_services",
    components: {},
    data() {
        return {
            items: [{
                    name: 'Настройка Yandex Direct',
                    description: '- Подбор ключевых слова\n- Настройка рекламной кампании\n- Написание объявлений и заголовков\n- Ведение рекламной кампании',
                    image: 'yandex-svgrepo-com.svg',
                    class: 'ya',
                    price: 9000
                },
                {
                    name: 'Разработка сайтов',
                    description: '- Дизайн\n- Верстка\n- Панель управления\n- Любой сложности',
                    image: 'code-svgrepo-com.svg',
                    class: 'code',
                    price: 15000
                },
                {
                    name: 'Продвижение в соц. сетях',
                    description: '- Дизайн\n- Контент план\n- Подбор аудитории и площадок\n- Таргетированная реклама',
                    image: 'telegram-svgrepo-com.svg',
                    class: 'telegram',
                    price: 5000
                }
            ]
        }
    }

}
</script>
<style scoped>
#development_services {
    padding-top: 120px;
    margin-top: -50px;
}

#development_services .container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}

h2 {
    text-align: center;
    line-height: 48px;
    font-size: 2.4vw;
    font-weight: bold;
    color: #172126;
    margin-bottom: 40px;
}

.development_services_item {
    background: #fff;
    border-radius: 4px;
    padding: 15px;
    box-shadow: 0 4px 20px rgb(117 115 111 / 10%);
    overflow: hidden;
    width: calc(100% / 3 - (15px * 2 / 3));
    transition: .2s;
    display: flex;
    flex-direction: column;
}

.development_services_item img {
    margin-bottom: 15px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    max-height: 248px;
    padding: 15px;
}


.development_services_item h3 {
    font-size: 16px;
    text-align: center;
}

.development_services_item p {
    font-size: 14px;
    color: #919191;
    margin: 15px 0 0;
    white-space: pre-line;
}

.development_services_item_footer {
    margin-top: auto;
}

.development_services_item_price {
    text-align: center;
    display: block;
    font-size: 24px;
    font-weight: bold;
    margin-top: 15px;
}

.development_services_item_button {
    background: rgba(var(--main-color), 1.0);
    width: 100%;
    margin-top: 15px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 15px;
    display: block;
}

.development_services_item_image_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.development_services_item:hover {
    transform: scale(1.05);
    transition: .2s;
}

.development_services_item:hover .development_services_item_icon {
    background-color: rgba(var(--main-color), 1.0);
    transition: .2s;
}

.development_services_item_icon {
    height: 140px;
    width: 140px;
    mask-repeat: no-repeat;
    background-color: #919191;
    transition: .2s;
}

.ya {
    mask-image: url('./images/yandex-svgrepo-com.svg');
}

.code {
    mask-image: url('./images/code-svgrepo-com.svg');
}

.telegram {
    mask-image: url('./images/telegram-svgrepo-com.svg');
}

@media (max-width: 1200px) {
    h2 {
        font-size: 4vw;
    }

}

@media (max-width: 991.9px) {
    h2 {
        font-size: 5vw;
    }
    #development_services .container {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 15px;
    }

    .development_services_item {
        width: calc(100% / 2 - (15px * 1 / 2));
    }

    .development_services_item_button {
        font-size: 1.8vw;
    }

}

@media (max-width: 576px) {
    h2 {
        font-size: 7vw;
    }
    .development_services_item {
        width: 100%;
    }

    .development_services_item_button {
        font-size: 4vw;
    }
}
</style>