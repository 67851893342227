<template>
    <section id="development_contacts">
        <h2>Контакты</h2>
        <div class="container">
            <div class="development_contacts_info_box">
                <developmentForm class="developmentForm" />
                <div class="development_contacts_main_info">
                    <span class="title">Рекламное агентство "Окей Город"</span>
                    <div class="development_contacts_main_info_item">
                        <div class="geo_icon"></div>
                        <span>
                            Раменское, ул. Советская, дом 14, 6 этаж, офис 602
                        </span>
                    </div>
                    <div class="development_contacts_main_info_item">
                        <div class="tel_icon"></div>
                        <span><a href="tel:89150904999">8(915)090-4999</a>
                        </span>
                    </div>
                    <div class="development_contacts_link_box">
                        <a href="https://vk.com/okaygorod_ramenskoe" target="_blank">
                            <div class="development_contacts_link vk">
                                <div class="vk_icon"></div>
                                <span>Написать ВКонтакте</span>
                            </div>
                        </a>
                        <a href="https://t.me/city_admin" target="_blank">
                            <div class="development_contacts_link telegram">
                                <div class="telegram_icon"></div>
                                <span>Написать в Telegram</span>
                            </div>
                        </a>
                        <a href="https://wa.me/79150904999" target="_blank">
                            <div class="development_contacts_link whatsapp">
                                <div class="whatsapp_icon"></div>
                                <span>Написать в Whats App</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="development_contacts_map_box">
                <iframe src="https://yandex.ru/map-widget/v1/-/CCUNALCyhD" onload="this.style.opacity = 1" frameborder="0" allowfullscreen="true"></iframe>
            </div>
        </div>
    </section>
</template>
<script>
import developmentForm from './form_callback';
export default {
    name: "development_contacts",
    components: {
        developmentForm
    },
    data() {
        return {

        }
    }

}
</script>
<style scoped>
#development_contacts {
    padding-top: 120px;
    margin-top: -50px;
}

#development_contacts .container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}

h2 {
    text-align: center;
    line-height: 48px;
    font-size: 2.4vw;
    font-weight: bold;
    color: #172126;
    margin-bottom: 40px;
}

.development_contacts_map_box {
    display: block;
    background: #fff;
    position: relative;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgb(117 115 111 / 10%);
    padding: 10px;
    padding-bottom: 36.25%;
    padding-top: 25px;
    height: 0;
}

.development_contacts_map_box iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 1s;
}

.development_contacts_info_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.development_contacts_info_box .developmentForm {
    width: calc(100% / 2 - (15px * 1 / 2));
}

.development_contacts_main_info {
    width: calc(100% / 2 - (15px * 1 / 2));
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgb(117 115 111 / 10%);

}

.development_contacts_main_info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.development_contacts_main_info_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

}

.development_contacts_main_info span {
    color: #919191;
}

.development_contacts_main_info .title {
    font-size: 24px;
    font-weight: bold;
    color: #212529;
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
}


.development_contacts_main_info img {
    border-radius: 4px;
    max-height: 200px;

}

.geo_icon {
    mask-image: url('./images/map-svgrepo-com.svg');
    mask-repeat: no-repeat;
    min-width: 30px;
    width: 30px;
    height: 30px;
    background-color: rgba(var(--main-color), 1.0);
}

.tel_icon {
    mask-image: url('./images/telephone.svg');
    mask-repeat: no-repeat;
    min-width: 30px;
    width: 30px;
    height: 30px;
    background-color: rgba(var(--main-color), 1.0);
}

.development_contacts_link_box {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.development_contacts_link {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding: 25px;
    transition: .2s;
}

.development_contacts_link:hover {
    transform: translateY(-3px);
    transition: .2s;
}

.development_contacts_link.vk {
    background: rgba(76, 117, 163, 1.0);
    color: #fff;
}

.development_contacts_link.vk span {
    color: #fff;
}

.development_contacts_link.telegram {
    background: rgba(42, 171, 238, 1.0);
    color: #fff;
}

.development_contacts_link.telegram span {
    color: #fff;
}

.development_contacts_link.whatsapp {
    background: rgba(37, 211, 102, 1.0);
    color: #fff;
}

.development_contacts_link.whatsapp span {
    color: #fff;
}

.vk_icon {
    mask-image: url('./images/vk-logo-svgrepo-com.svg');
    mask-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-color: #fff;

}

.telegram_icon {
    mask-image: url('./images/telegram-svgrepo-com.svg');
    mask-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-color: #fff;

}

.whatsapp_icon {
    mask-image: url('./images/whatsapp-svgrepo-com.svg');
    mask-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-color: #fff;

}

@media (max-width: 1200px) {
    h2 {
        font-size: 4vw;
    }

}

@media (max-width: 991.9px) {
    h2 {
        font-size: 5vw;
    }

    .development_contacts_info_box .developmentForm {
        width: 100%;
    }

    .development_contacts_main_info {
        width: 100%;
    }

}

@media (max-width: 576px) {
    h2 {
        font-size: 7vw;
    }

    .development_contacts_main_info .title {
        font-size: 7vw;
        text-align: center;
    }

    .development_contacts_main_info span {
        gap: 15px;
    }
    .development_contacts_link {
        font-size: 14px;
    }
}
</style>